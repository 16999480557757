<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="checkAccess('sailorMedical', 'preVerification', sailorDocument) || checkAccess('sailorMedical', 'editStatus', sailorDocument)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'labelName')
  div(v-if="checkAccess('sailorMedical', 'preVerification', sailorDocument) && body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED").w-100
    Reject
  div.col-12.m
    v-btn(
      v-if="body.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED"
      @click="checkAccess('sailorMedical', 'preVerification', sailorDocument) ? saveNewStatus() : checkSave()"
      :loading="isLoading"
      color="success") {{ checkAccess('sailorMedical', 'preVerification', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: this.sailorDocument.status_document
      },
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose']),
    ...mapMutations(['decrementUserNotification']),
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    listStatuses () {
      if (checkAccess('admin')) return this.statusChoose('BackOffice').concat(this.statusChoose('QualificationDoc'))
      else if (checkAccess('verifierBackOfficeSecond')) return this.statusChoose('QualificationDoc').filter(i => [7, 19, 20, 37].includes(i.id))
      else return this.statusChoose('QualificationDoc')
    }
  },
  methods: {
    ...mapActions(['updateMedicalCertificateById']),

    /** Verification confirmation */
    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },

    /** Edit document status */
    async saveNewStatus () {
      this.isLoading = true

      if (!checkAccess('sailorMedical', 'preVerification', this.sailorDocument)) {
        this.body.status_document = STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.VERIFICATION
      }

      const response = await this.updateMedicalCertificateById({ ...this.$route.params, body: { ...this.body } })
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('editedMedDoc')
        if (this.sailorDocument.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.VERIFICATION &&
          response.data.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.VERIFICATION) {
          this.decrementUserNotification('documents_on_verification')
        }
      }
      this.isLoading = false
    }
  }
}
</script>
